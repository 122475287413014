import { useLazyQuery } from '@apollo/client'
import { GET_PRODUCTS } from '../graphql'

interface Product {
  hp_as_low_as_price: number
  hp_short_config_name: string
  hp_supply_type_label: string
  sku: string
  hp_pages_per_month: number
  hp_rollover_pages: number
  hp_overage_block_size: number
  hp_overage_block_price: number
  hp_max_rollover_pages: number
  hp_trial_pages_per_month: null
  price: {
    regularPrice: {
      amount: { value: number }
    }
  }
  image: {
    url: string
  }
  thumbnail: {
    url: string
  }
}

interface ProductBundleOption {
  label: string
  product: Product
}

interface ProductBundleItem {
  title: string
  sku: string
  options: ProductBundleOption[]
}

export interface CoreOfferPlanInfo {
  sku: string
  title: string
  productimage: string
}

interface ProductBundle {
  hp_max_days_to_return: number
  name: string
  sku: string
  items: ProductBundleItem[]
}

interface ProductResult {
  products: {
    items: ProductBundle[]
  }
}

const useLazyGetProducts = () => useLazyQuery<ProductResult>(GET_PRODUCTS)

const findCoreOfferPlan = (
  results: ProductResult,
  sku: string
): CoreOfferPlanInfo | null => {
  const printerBundle = results?.products?.items[0]
  const coreOfferBundle: ProductBundleItem = printerBundle?.items.find(
    (item) => item.title == 'Core Offer'
  )

  const coreOffer = coreOfferBundle?.options?.find(
    (plan) => plan.product.sku === sku
  )

  if (!coreOffer) return null
  return {
    sku: coreOffer.product?.sku,
    title: coreOffer.product?.hp_short_config_name,
    productimage: coreOffer.product?.thumbnail.url
  }
}

const findIsCissPrinter = async (results: ProductResult) => {
  const productsBundle = results?.products?.items[0]
  const itemBundle = {
    options: productsBundle?.items?.reduce((accumulator, item) => {
      const filtered = item?.options?.filter(
        (option) => option?.product?.hp_supply_type_label === 'inkTank'
      )
      return accumulator.concat(filtered)
    }, [])
  }
  const isCissPrinter = itemBundle?.options?.length > 0 ? true : false
  return isCissPrinter
}
export { useLazyGetProducts, findCoreOfferPlan, findIsCissPrinter }
