import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { createApolloClient } from '../../api/adapters'
import { TranslatorFunctionType } from '../../types/localization'
import { MfePropsType } from '../../types/mfeProps'
import InitialComponent from '../InitialComponent'

const ShipmentTracking = ({
  t,
  authProvider,
  stack,
  localization,
  analytics,
  navigation
}: MfePropsType & { t: TranslatorFunctionType }) => {
  const graphqlClient = createApolloClient(authProvider, stack)
  return (
    <ApolloProvider client={graphqlClient}>
      <InitialComponent
        commonProps={{
          t,
          authProvider,
          stack,
          analytics,
          localization,
          navigation
        }}
      />
    </ApolloProvider>
  )
}

export default ShipmentTracking
