import { AccordionInfo } from '@monetization/hpaip-notification-rules-react'
import React from 'react'
import {
  AccordionHeaderTitle,
  AccordionText,
  FaqContainer,
  StyledAccordion
} from './styles'
import CommonProps from '../../types/commonProps'

interface FaqCardProps {
  commonProps: CommonProps
  isCissPrinter: boolean
}

export const FaqCard: React.FunctionComponent<FaqCardProps> = ({
  commonProps,
  isCissPrinter
}) => {
  const { t } = commonProps

  const cissAccordionInfo: AccordionInfo[] = [
    {
      header: t(
        'shipment.faq.accordion.item1.title',
        'I was charged. Why didn’t I get a shipment?'
      ),
      content: (
        <div
          className="accordion-title"
          data-title={t(
            'shipment.faq.accordion.item1.title',
            'I was charged. Why didn’t I get a shipment?'
          )}
        >
          <AccordionText
            text={t(
              'shipment.faq.accordion.item1.content-ciss',
              'After your first shipment, we send ink bottles as needed based on your ink usage (not monthly). Keep your printer connected to Wi-Fi, so we can monitor your ink levels and send replacement ink bottles before you need them. The ink bottles hold more ink/toner than retail bottles and last longer. Your shipments may be less frequent than you expect. You can check ink levels on your printer screen or with the HP Smart app. Use the HP Virtual Assistant if you need to request a replacement ink or toner shipment.'
            )}
          />
        </div>
      )
    }
  ]
  /* istanbul ignore next */
  const onExpandCallback = (...props) => {
    const item = props[2]
    setTimeout(() => {
      const targetElement = document.getElementById(item.id)
      const elementHeight = targetElement?.offsetHeight

      if (elementHeight > 200) {
        targetElement.style.cssText = 'height: 200px; overflow-y: scroll;'
      }
    }, 10)
  }
  const nonCISSsAccordionInfo: AccordionInfo[] = [
    {
      header: t(
        'shipment.faq.accordion.item1.title',
        'I was charged. Why didn’t I get a shipment?'
      ),
      content: (
        <div
          className="accordion-title"
          data-title={t(
            'shipment.faq.accordion.item1.title',
            'I was charged. Why didn’t I get a shipment?'
          )}
        >
          <AccordionText
            text={t(
              'shipment.faq.accordion.item1.content',
              'After your first shipment, we send ink cartridges as needed based on your ink or toner usage (not monthly). Keep your printer connected to Wi-Fi, so we can monitor your ink levels and send replacement cartridges before you need them. HP ink cartridges hold more ink/toner than retail cartridges and last longer. Your shipments may be less frequent than you expect. You can check ink/toner levels on your printer screen or with the HP Smart app. Use the HP Virtual Assistant if you need to request a replacement ink or toner shipment.'
            )}
          />
        </div>
      )
    },
    {
      header: t(
        'shipment.faq.accordion.item2.title',
        'Did HP send me the wrong cartridges?'
      ),
      content: (
        <div
          className="accordion-title"
          data-title={t(
            'shipment.faq.accordion.item2.title',
            'Did HP send me the wrong cartridges?'
          )}
        >
          <AccordionText
            text={t(
              'shipment.faq.accordion.item2.content.part1',
              'HP ink cartridges may be larger than you expect or have a different model number than store-bought cartridges. You can feel confident that they are compatible with your printer. Visit [HP Support](https://www.hp.com/all-in-plan/printer-support?target=_blank) for help installing cartridges.'
            )}
          />
        </div>
      )
    },
    {
      header: t(
        'shipment.faq.accordion.item3.title',
        'How do I return cartridges for recycling?'
      ),
      content: (
        <div
          className="accordion-title"
          data-title={t(
            'shipment.faq.accordion.item3.title',
            'How do I return cartridges for recycling?'
          )}
        >
          <AccordionText
            text={t(
              'shipment.faq.accordion.item3.content.part1',
              'We encourage you to return your used ink cartridges in the postage-paid recycling envelopes included in your box. You can also request an envelope from your account dashboard. You can fit several cartridges in each recycling envelope, so not all your shipments will include a new envelope. For toner cartridges, use the printed shipping label in the box or print a shipping label from your dashboard. HP Planet Partners recycle all cartridges received. Information varies by country.'
            )}
          />
        </div>
      )
    }
  ]

  const accordionItems = isCissPrinter
    ? cissAccordionInfo.map((info, i) => ({
        header: {
          startArea: <AccordionHeaderTitle>{info.header}</AccordionHeaderTitle>
        },
        id: `FaqCardItem-${i}`,
        content: info.content,
        expanded: false
      }))
    : nonCISSsAccordionInfo.map((info, i) => ({
        header: {
          startArea: <AccordionHeaderTitle>{info.header}</AccordionHeaderTitle>
        },
        id: `FaqCardItem-${i}`,
        content: info.content,
        expanded: false
      }))

  return (
    <FaqContainer>
      <StyledAccordion
        appearance="dropShadow"
        behavior="singleExpand"
        items={accordionItems}
        onExpand={onExpandCallback}
      />
    </FaqContainer>
  )
}
