import React, { useState, createContext, useContext } from 'react'

import type { NotificationProps } from '../../../../types/notification'
import {
  CdmEvent,
  PublishCdmEventsOptions,
  useAnalytics
} from '../../../contexts'

interface NotificationProviderProps {
  children: React.ReactNode
}

interface NotificationContextProps {
  notification: NotificationProps
  setNotification: (
    notification: NotificationProps,
    notificationEvent?: NotificationEvent
  ) => void
}

interface NotificationEvent {
  event: CdmEvent
  options?: PublishCdmEventsOptions
}

export const NotificationContext = createContext<NotificationContextProps>({
  notification: null,
  setNotification: () => null
})

export const NotificationProvider = ({
  children
}: NotificationProviderProps) => {
  const analytics = useAnalytics()
  const [notification, setNotification] = useState<NotificationProps>()

  const handleNotification = (
    notification: NotificationProps,
    notificationEvent?: NotificationEvent
  ) => {
    setNotification(notification)

    if (notificationEvent) {
      analytics?.publishEvent(
        notificationEvent.event,
        notificationEvent.options
      )
    }
  }

  return (
    <NotificationContext.Provider
      value={{ notification, setNotification: handleNotification }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = () => useContext(NotificationContext)
