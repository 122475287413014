import insane from '@vtex/insane'
import escapeHtml from 'escape-html'
import marked, { Renderer } from 'marked'
import React, { memo, useEffect, useMemo, useRef, useState } from 'react'
import sanitizerConfig from './sanitizer.json'

interface IMarkdownTextProps {
  text: string
  className?: string
}

const getLevel = (level: number) => (level > 0 && level <= 6 ? level : 6)

const getTargetFromUrl = (url: string) => {
  const urlSplit = url.split('?')

  if (urlSplit.length < 2) {
    return ''
  }

  const [, qs] = urlSplit
  const hastTargetBlank = qs.includes('target=_blank')

  return hastTargetBlank ? 'target=_blank' : ''
}

const renderHeading = () => (text: string, level: number) => {
  const levelNumber = getLevel(level)
  return `<h${levelNumber}>${text}</h${levelNumber}>`
}

const MarkdownText: React.FunctionComponent<IMarkdownTextProps> = ({
  text,
  className
}) => {
  const [isMounted, setMounted] = useState(false)
  const renderer = useRef<Renderer>()

  useEffect(() => {
    setMounted(true)
  }, [])

  if (!isMounted) {
    renderer.current = new Renderer()
    renderer.current.paragraph = (content: string) => `<p>${content}</p>`
    renderer.current.strong = (content: string) => `<strong>${content}</strong>`
    renderer.current.em = (content: string) => `<em>${content}</em>`
    renderer.current.heading = renderHeading()
    renderer.current.link = (href: string, title: string, content: string) => {
      const targetAtr = getTargetFromUrl(href)
      const targetRemoved = targetAtr
        ? href.replace(/target=_blank/, '').replace(/\?&/, '?')
        : href

      // clean trailing ? or &
      const cleanHref = targetRemoved.replace(/(\?|&)$/, '')
      const titleAtr = title ? `title="${title}"` : ''

      let finalLink = `<a href="${cleanHref}"`

      // get anything between { and }
      const regex = new RegExp(/{(.*?)}/).exec(content)
      if (regex !== null) {
        finalLink += ` data-control-name="${regex[1].trim()}"`
        content = content.replace(regex[0], '')
      }

      if (titleAtr) {
        finalLink += ` ${titleAtr}`
      }

      if (targetAtr) {
        finalLink += ` ${targetAtr}`
      }

      finalLink += `>${content}</a>`

      return finalLink
    }

    renderer.current.html = (html) => escapeHtml(html)
    renderer.current.table = (header, body) => `
          <table>
            <thead>
              ${header}
            </thead>
            <tbody>
              ${body}
            </tbody>
          </table>`
    renderer.current.tablerow = (content) => {
      return `<tr>\n${content}</tr>\n`
    }

    renderer.current.tablecell = (content, flags) => {
      const type = flags.header ? 'th' : 'td'
      const tag = `<${type}>`

      return `${tag + content}</${type}>\n`
    }

    renderer.current.image = (href: string, title: string, content: string) =>
      `<img src="${href}" alt="${content}" ${
        title ? `title="${title}"` : ''
      } />`
    renderer.current.list = (body: string, ordered: boolean) => {
      const tag = ordered ? 'ol' : 'ul'

      return `<${tag}>${body}</${tag}>`
    }

    renderer.current.listitem = (content: string) => `<li>${content}</li>`
  }

  const html = useMemo(() => {
    marked.setOptions({
      breaks: true,
      renderer: renderer.current
    })

    return insane(marked(text), sanitizerConfig)
  }, [text])

  return (
    <div className={className} dangerouslySetInnerHTML={{ __html: html }} />
  )
}

const MemoizedMarkdownText = memo(MarkdownText)

export { MemoizedMarkdownText }

export default MarkdownText
