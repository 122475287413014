import React from 'react'
import {
  CardDetailsDate,
  CardDetailsNum,
  Divider,
  CardDetailsDatesContainer,
  QuantityLabel
} from './styles'
import { Link } from '@monetization/hpaip-ui-shared-components'
import { HistoryInfo } from '../../types/shipmentCard'
import CommonProps from '../../types/commonProps'

type PrintCardAccordionContentHistoryItemProps = {
  shipmentCardTitle: string
  historyInfo: HistoryInfo
  showDivider: boolean
  commonProps: CommonProps
}

export const PrintCardAccordionContentHistoryItem: React.FunctionComponent<
  PrintCardAccordionContentHistoryItemProps
> = ({ historyInfo, showDivider, commonProps }) => {
  const { t, localization } = commonProps

  const formatDate = (d: Date) =>
    d.toLocaleDateString(localization.locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })

  return (
    <>
      <CardDetailsDatesContainer>
        <CardDetailsDate>{formatDate(historyInfo.date)}</CardDetailsDate>
        {!!historyInfo.quantity && (
          <>
            <QuantityLabel>
              {t('shipment.print-cards.quantity', 'qty')}:
            </QuantityLabel>
            <CardDetailsNum>{historyInfo.quantity}</CardDetailsNum>
          </>
        )}
        <Link target="_blank" href={historyInfo.url} fontSize={2}>
          {historyInfo.id}
        </Link>
      </CardDetailsDatesContainer>
      {showDivider && <Divider />}
    </>
  )
}
