import { ShipmentData } from './getShipmentDataFromOrders'
import type { TimePeriod } from '@monetization/hpaip-notification-rules-react'

export const filterShipmentHistoryData = (
  shipments: ShipmentData[],
  filter: TimePeriod
) => {
  if (filter === 'all') return shipments
  const filterBy = filter.split('-')

  if (filterBy[0] === 'last') return filterByMonths(shipments, filterBy[1])

  if (filterBy[0] === 'year') return filterByYear(shipments, filterBy[1])
}

const filterByMonths = (shipments, months) => {
  const date = new Date()
  const filterDate = new Date(
    date.setMonth(date.getMonth() - months)
  ).toISOString()

  return shipments.filter((shipment) => {
    return shipment.date > filterDate
  })
}

const filterByYear = (shipments, year) => {
  return shipments.filter((shipment) => shipment.date.split('-')[0] === year)
}
