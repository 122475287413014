import React, { createContext, useContext, useReducer } from 'react'

// the RenderControlContext is used to control force updates on veneer
// specific components which are not re-rendering when their props change

interface RenderControlContextProps {
  renderCount: number
  triggerRender: React.DispatchWithoutAction
}

interface RenderControlContextProviderProps {
  children: React.ReactNode
}

const RenderControlContext = createContext<RenderControlContextProps>({
  renderCount: 0,
  triggerRender: () => 0
})

const RenderControlProvider = ({
  children
}: RenderControlContextProviderProps) => {
  const [renderCount, triggerRender] = useReducer(
    (state: number) => state + 1,
    0
  )

  return (
    <RenderControlContext.Provider value={{ renderCount, triggerRender }}>
      {children}
    </RenderControlContext.Provider>
  )
}

const useRenderControlContext = () => useContext(RenderControlContext)

export { RenderControlProvider, useRenderControlContext }
