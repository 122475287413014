import React from 'react'
import { PrinterOfflineText } from './styles'
import CommonProps from '../../types/commonProps'

interface PrinterOfflineModuleProps {
  cardTitle: string
  commonProps: CommonProps
}

const PrinterOfflineModule: React.FunctionComponent<
  PrinterOfflineModuleProps
> = ({ commonProps }) => {
  const { t } = commonProps
  return (
    <PrinterOfflineText>
      {t(
        'shipment.print-cards.printer-offline',
        'Printer offline: Your printer appears to be offline. We cannot read your ink levels or send ink until the printer is connected to the internet.'
      )}{' '}
    </PrinterOfflineText>
  )
}

export default PrinterOfflineModule
