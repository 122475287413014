import styled from 'styled-components'
import { tokens } from '@veneer/tokens'
import Card from '@veneer/core/dist/esm/scripts/card'
import Accordion from '@veneer/core/dist/esm/scripts/accordion'
import { Link } from '@monetization/hpaip-ui-shared-components'
import { ShipmentCardType } from '../../types/shipmentCard'

const customColors = {
  colorCyan: '#1BA4F0',
  colorMagenta: '#F7448B',
  colorDeepCerulean: '#027AAE',
  colorSunglow: '#FED931'
} as const

const { colorCyan, colorMagenta, colorDeepCerulean, colorSunglow } =
  customColors

const { gray12 } = tokens.color
const { lineHeight2, lineHeight4, size1, size2, size4 } = tokens.typography

const {
  smMax,
  size1: layoutSize1,
  size2: layoutSize2,
  size3: layoutSize3,
  size4: layoutSize4,
  size5: layoutSize5,
  size6: layoutSize6,
  cornerRadius4
} = tokens.layout

export const GridLayout = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${layoutSize4};
  margin: 0 auto ${layoutSize6};
  @media only screen and (max-width: ${smMax}) {
    display: flex;
    flex-direction: column;
  }
`

export const StyledCard = styled(Card)`
  && {
    border-radius: ${cornerRadius4};
    display: flex;
    flex-direction: row;
    height: fit-content;
    border: none;
  }
`

const colorForCartridgeTypeMap: Record<ShipmentCardType, string> = {
  black: 'black',
  cyan: colorCyan,
  magenta: colorMagenta,
  yellow: colorSunglow,
  paper: '#0076AD1A',
  printer: colorDeepCerulean,
  tricolor: `linear-gradient(${colorCyan} 33%, 33%, ${colorMagenta} 66%, 66%, ${colorSunglow} 100%)`
}

export const CardCustomColor = styled.div<{
  shipmentInfoType: ShipmentCardType
}>`
  background: ${({ shipmentInfoType }) =>
    colorForCartridgeTypeMap[shipmentInfoType]};
  border-radius: ${cornerRadius4} 0 0 ${cornerRadius4};
  width: 36px;
  height: auto;
`

export const Content = styled.div`
  margin: 0 25px;
  width: 100%;
`

export const Title = styled.h2`
  font-size: ${size4};
  line-height: ${lineHeight4};
  margin-top: ${layoutSize5};
`

export const StyledDate = styled.h3`
  font-size: ${size1};
  line-height: ${lineHeight2};
  margin-bottom: ${layoutSize1};
`

export const StyledAccordion = styled(Accordion)`
  && {
    height: fit-content;
    background-color: transparent;
    box-shadow: none;
    padding-bottom: ${layoutSize5};

    div {
      padding: 0;
      border: 0;
      border-radius: 0;
      line-height: ${lineHeight2};
      margin-left: 0;
      font-size: ${size1};
      color: ${gray12};
    }

    div[role='button'] {
      display: flex;
      justify-content: space-between;
      overflow: visible;

      svg {
        display: flex;
        align-self: start;
      }
    }

    &:last-of-type div[role='button'] {
      border: 0;
    }

    a {
      color: ${colorDeepCerulean};
    }
  }
`

export const AccordionHeader = styled.div`
  cursor: auto;
  position: relative;
  height: fit-content;
  margin-bottom: ${layoutSize4};
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const AccordionSubtitle = styled.div`
  margin-top: ${layoutSize3};
  font-size: ${size1};
  a {
    font-size: ${size1};
  }
`

export const CardDetailsDatesContainer = styled.div`
  margin: ${layoutSize4} 0;
`

export const CardDetailsTitle = styled.p`
  margin-bottom: ${layoutSize4};
`

export const CardDetailsNum = styled.span`
  padding-right: ${layoutSize3};
  font-weight: bold;
`

export const CardDetailsDate = styled.span`
  padding-right: ${layoutSize4};
`

export const Divider = styled.hr`
  border: 1px solid #ebebeb;
`

export const PrinterOfflineText = styled.p`
  font-size: ${size1};
  color: ${gray12};
`

export const TrackingNumberContainer = styled.div`
  display: flex;
  align-items: center;
`

export const QuantityLabel = styled.span`
  padding-right: ${layoutSize1};
  line-height: ${lineHeight2};
  text-transform: capitalize;
`

export const QuantityValue = styled.span`
  padding-right: ${layoutSize2};
  font-weight: bold;
  line-height: ${lineHeight2};
`
export const StyledAnchor = styled(Link)`
  font-size: ${size2};
  overflow-wrap: anywhere;
`
