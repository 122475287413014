import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { layout } = tokens

export const PrinterListDiv = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${layout.size4};
  margin-right: 40px;
`
