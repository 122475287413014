import {
  useLazyFetchRequest,
  FulfillmentClient
} from '@monetization/hpaip-notification-rules-react'
import { useEffect } from 'react'

const useFulfillmentOrders = (authProvider, stack, currentSubscription) => {
  const fulfillmentClient = new FulfillmentClient(authProvider, stack)

  const [
    fetchFulfillmentOrderByTenantIdAndSubscriptionId,
    fulfillmentOrdersState
  ] = useLazyFetchRequest<[]>(async () => {
    const paginatedOrders =
      await fulfillmentClient.getFulfillmentOrderByTenantIdAndSubscriptionId(
        currentSubscription.tenantId,
        currentSubscription.subscriptionId
      )

    return paginatedOrders
  })

  useEffect(() => {
    if (currentSubscription && !fulfillmentOrdersState.called) {
      fetchFulfillmentOrderByTenantIdAndSubscriptionId()
    }
  }, [currentSubscription, fulfillmentOrdersState.called])

  const isEmptyOrError =
    fulfillmentOrdersState.called &&
    !fulfillmentOrdersState.loading &&
    (!fulfillmentOrdersState?.data?.length || !!fulfillmentOrdersState?.error)

  return {
    ordersState: fulfillmentOrdersState,
    isEmptyOrError
  }
}

export default useFulfillmentOrders
