import { DayMonthYear } from '../../types/dateType'

export function addDay(date: string | number, qtDays: number): Date {
  const returningDate = new Date(date)

  returningDate.setDate(returningDate.getDate() + qtDays)

  return new Date(returningDate)
}

export function getDayMonthYear(date: string | number): DayMonthYear {
  const workingDate = new Date(date)

  const result = {
    day: workingDate.getUTCDate(),
    month: workingDate.getUTCMonth() + 1,
    year: workingDate.getUTCFullYear()
  }

  return result
}

export function getDayMonthYearFromISOStringDate(date: string): DayMonthYear {
  const dateWithoutTime = date.split('T')[0]

  const [year, month, day] = dateWithoutTime.split('-')

  return {
    day: Number.parseInt(day),
    month: Number.parseInt(month),
    year: Number.parseInt(year)
  }
}

export function generateDateFromISOString(date: string): Date {
  const { day, month, year } = getDayMonthYearFromISOStringDate(date)

  return new Date(year, month - 1, day)
}

export function getAllDaysMonthsYearsFromInterval(
  startDate: string,
  endDate: string
) {
  let increasingDate: Date = new Date(startDate)
  const endDateNoTime: Date = new Date(endDate)
  const result: DayMonthYear[] = []

  while (increasingDate <= endDateNoTime) {
    result.push(getDayMonthYear(increasingDate.getTime()))

    increasingDate = addDay(increasingDate.toString(), 1)
  }

  return result
}

export function isBetweenDates(start: Date, end: Date): boolean {
  const now = new Date().getTime()

  return now >= start.getTime() && now <= end.getTime()
}

export function generateISOStringDate(
  day: number,
  month: number,
  year: number
) {
  const strDay =
    day.toString().length === 1 ? '0' + day.toString() : day.toString()
  const strMonth =
    month.toString().length === 1 ? '0' + month.toString() : month.toString()
  const strYear = year.toString()
  return `${strYear}-${strMonth}-${strDay}T12:00:00.000Z`
}

export function isSameDayMonthYear(
  value1: DayMonthYear,
  value2: DayMonthYear
): boolean {
  return value1.day == value2.day && isSameMonthAndYear(value1, value2)
}

export function isSameMonthAndYear(
  value1: DayMonthYear,
  value2: DayMonthYear
): boolean {
  return value1.month == value2.month && value1.year == value2.year
}

export function formatDateMonthLong(
  date: string | Date,
  locale = 'en-US',
  year = false
) {
  if (!date) return
  const dateObj = new Date(date)
  if (!dateObj) return

  return new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'long',
    year: year ? 'numeric' : undefined
  }).format(dateObj)
}

export function formatDateMonthShort(
  date: string | Date,
  locale = 'en-US',
  year = true
) {
  if (!date) return
  const dateObj = new Date(date)
  if (!dateObj) return

  return new Intl.DateTimeFormat(locale, {
    day: 'numeric',
    month: 'short',
    year: year ? 'numeric' : undefined
  }).format(dateObj)
}

export function dateDiffInDays(date1: Date, date2: Date) {
  const _MS_PER_DAY = 1000 * 60 * 60 * 24
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate())
  const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate())

  return (utc2 - utc1) / _MS_PER_DAY
}
