import Accordion from '@veneer/core/dist/esm/scripts/accordion'
import Select from '@veneer/core/dist/esm/scripts/select'
import { tokens } from '@veneer/tokens'
import styled from 'styled-components'
import Button from '@veneer/core/dist/esm/scripts/button'
import Table from '@veneer/core/dist/esm/scripts/table'
import React from 'react'

const { white, gray9, highContrastGray } = tokens.color
const { size4, lineHeight4 } = tokens.typography

const {
  smMin,
  size8: layoutSize8,
  size3: layoutSize3,
  size5: layoutSize5,
  size6: layoutSize6
} = tokens.layout

export const HistoricTableWrapper = styled.div<{
  areaName: string
}>`
  grid-area: ${({ areaName }) => areaName};
`

export const HistoricTableAccordion = styled(Accordion)`
  background: ${white};
  padding: 0;

  & > div {
    border: none;

    > [role='button'] {
      padding: ${layoutSize8};
    }
  }

  .vn-animated-height__wrapper-inner > div {
    margin: 0;
    border: none;
    padding: 0 ${layoutSize8} ${layoutSize8};
  }
`

export const Title = styled.h3`
  font-size: ${size4};
  line-height: ${lineHeight4};
`

export const ContentContainer = styled.div`
  border-top: 1px solid #ebebeb;
  padding-top: ${layoutSize8};
`

export const PeriodTimeSelect = styled(Select)`
  width: 100%;
  margin-bottom: ${layoutSize6};

  @media screen and (min-width: ${smMin}) {
    max-width: 270px;
  }
`

export const ContentWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: ${smMin}) {
      flex-direction: column;
      align-items: start;
    }
  }
`

export const DownloadAllInvoicesButton = styled(Button).withConfig({
  shouldForwardProp: (prop: string) => !['download'].includes(prop)
})<{ download: boolean }>`
  && {
    min-width: 215px;
    margin-bottom: ${layoutSize6};
    margin-left: ${layoutSize5};
    display: none;
    /* TODO: Post MVP need to change the display rules in PAASPE-1042 */
    /* display: ${({ download }) => (download ? 'block' : 'none')}; */

    @media only screen and (max-width: ${smMin}) {
      margin-left: 0;
      width: 100%;
    }
  }
`

// interface TablePaginationProps extends TableProps {
//   disableSelect?: boolean
//   hasNextPage?: boolean
// }

export const StyledTable = styled(Table as React.FC<any>).withConfig({
  shouldForwardProp: (prop) =>
    'hasNextPage' !== prop && 'disableSelect' !== prop
})`
  && {
    ${({ disableSelect }) =>
      disableSelect
        ? `
    & > div:last-child div > div:first-child {
      button {
        color: ${highContrastGray};
        pointer-events: none;
        border: 1px solid rgba(33, 33, 33, 0.05);
      }
      span {
        color: ${highContrastGray};
      }
    }
    `
        : ''}
    ${({ hasNextPage }) =>
      hasNextPage
        ? ''
        : `
      button.page {
        pointer-events: none;
        border: none;
        background: none;
        color: ${gray9};
      }
    `}
    & > div:last-child {
      white-space: nowrap;
      & > div {
        flex-wrap: wrap;
        // items per page
        & > div:first-child {
          margin-bottom: ${layoutSize3};
          & > span {
            // hide last page / total pages
            display: none;
          }
        }
        // pagination
        & > div:last-child {
          ul {
            flex-flow: row;
            justify-content: flex-end;
          }
        }
      }
    }
  }

  // overwrites veneer table styling for specific table set by className
  &&.payment-history-table,
  &&.shipment-history-table {
    thead {
      tr th {
        text-transform: uppercase;
        white-space: nowrap;
      }
    }
    th#description,
    th#trackingNumber {
      pointer-events: none;
    }

    tbody > tr td {
      min-width: 320px;
    }
    tbody > tr > :first-child {
      white-space: nowrap;
      min-width: auto;
    }
    tbody > tr > :last-child {
      white-space: nowrap;
      min-width: auto;
    }
    &&.empty-table {
      table thead {
        display: none;
      }
    }
  }
`
