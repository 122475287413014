import React from 'react'
import { GridLayout, StyledCard } from './styles'
import { PrintCardContent } from './PrintCardContent'
import { ShipmentCard } from '../../types/shipmentCard'
import CommonProps from '../../types/commonProps'

type PrintCardsProps = {
  shipmentCards: ShipmentCard[]
  shipmentHistoryTableRef: React.RefObject<HTMLDivElement>
  isCustomError?: boolean
  isCissPrinter?: boolean
  commonProps: CommonProps
  printerData: any
}

export const PrintCards = ({
  shipmentCards,
  shipmentHistoryTableRef,
  isCustomError,
  isCissPrinter,
  commonProps,
  printerData
}: PrintCardsProps) => {
  const { printer, isLoading } = printerData

  if (!isLoading && !printer) return null
  if (shipmentCards.length < 1) return null

  return (
    <GridLayout>
      {shipmentCards.map((card) => (
        <StyledCard
          appearance="dropShadow"
          key={card.title}
          content={
            <PrintCardContent
              shipmentCard={card}
              shipmentHistoryTableRef={shipmentHistoryTableRef}
              isCustomError={isCustomError}
              isCissPrinter={isCissPrinter}
              commonProps={commonProps}
            />
          }
        />
      ))}
    </GridLayout>
  )
}
