import {
  AnalyticsEvents,
  CdmEvent
} from '@monetization/hpaip-ui-shared-components'

type CustomAnalyticsEvent = Partial<CdmEvent>

interface RequiredControlDetailEvent extends CustomAnalyticsEvent {
  controlDetail: string
}
interface FaqLinkEvent extends CustomAnalyticsEvent {
  controlDetail: string
  controlName: 'Overview' | 'UpdatePlan'
}

interface NotificationSupportLinkEvent extends CustomAnalyticsEvent {
  screenName: 'PrinterOfflineNotification' | 'SubscriptionHasBeenCancelled'
  controlName: 'ContactSupport'
  screenMode?:
    | 'PleaseReturn'
    | 'ReturnProcessing'
    | 'ReturnSuccess'
    | 'ReturnFailure'
    | 'PostRemorse'
}

const events: AnalyticsEvents = {
  ShipmentTrackingDisplayed: () => ({
    screenName: 'ShipmentTracking',
    action: 'ScreenDisplayed'
  }),
  DisplayedOfflineNotification: () => ({
    screenPath: '/ReactPaasDashboardShipmentTracking/ShipmentTracking/',
    screenName: 'PrinterOfflineNotification',
    action: 'ModuleDisplayed'
  }),
  ClickTrackingNumber: ({ controlDetail }: RequiredControlDetailEvent) => ({
    screenName: 'ShipmentTracking',
    action: 'ControlHyperLinkClicked',
    controlName: 'TrackingNumber',
    controlDetail
  }),
  CardAccordianExpanded: ({ controlDetail }: RequiredControlDetailEvent) => ({
    screenName: 'ShipmentTracking',
    action: 'ControlAccordianExpanded',
    controlName: 'ItemCard',
    controlDetail
  }),
  CardAccordianCollapsed: ({ controlDetail }: RequiredControlDetailEvent) => ({
    screenName: 'ShipmentTracking',
    action: 'ControlAccordianCollapsed',
    controlName: 'ItemCard',
    controlDetail
  }),
  ClickOrderHistory: ({ controlDetail }: RequiredControlDetailEvent) => ({
    screenName: 'ShipmentTracking',
    action: 'ControlHyperLinkClicked',
    controlName: 'OrderHistory',
    controlDetail
  }),
  FAQAccordianExpanded: () => ({
    screenName: 'ShipmentTracking',
    action: 'ControlAccordianExpanded',
    controlName: 'Faq'
  }),
  ClickFaqLink: ({ controlName, controlDetail }: FaqLinkEvent) => ({
    screenName: 'ShipmentTracking',
    action: 'ControlHyperLinkClicked',
    controlName,
    controlDetail
  }),
  ClickRecyclingEnvelope: () => ({
    screenName: 'ShipmentTracking',
    action: 'ControlHyperLinkClicked',
    controlName: 'RecyclingEnvelope'
  }),
  HistoryTableAccordian: () => ({
    screenName: 'ShipmentTracking',
    action: 'ControlAccordianExpanded', // this action is handled by the component
    controlName: 'ShipmentHistory'
  }),
  ClickTableTrackingNumber: () => ({
    screenName: 'ShipmentTracking',
    action: 'ControlHyperLinkClicked',
    controlName: 'TrackingNumber'
  }),
  ClickNotificationSupportLink: ({
    controlName,
    screenMode,
    screenName
  }: NotificationSupportLinkEvent) => ({
    screenName,
    action: 'ControlHyperLinkClicked',
    controlName,
    ...(screenMode ? { screenMode } : undefined),
    screenPath: '/ReactPaasDashboardShipmentTracking/ShipmentTracking/'
  })
} as const

export default events
