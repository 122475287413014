type CodeCurrency = {
  [key: string]: string
}

const currencysCodeAndSymbol: CodeCurrency = {
  FN: '؋',
  ARS: '$',
  AWG: 'ƒ',
  AUD: '$',
  AZN: '₼',
  BSD: '$',
  BBD: '$',
  BYN: 'Br',
  BZD: 'BZ$',
  BMD: '$',
  BOB: '$b',
  BAM: 'KM',
  BWP: 'P',
  BGN: 'лв',
  BRL: 'R$',
  BND: '$',
  KHR: '៛',
  CAD: '$',
  KYD: '$',
  CLP: '$',
  CNY: '¥',
  COP: '$',
  CRC: '₡',
  HRK: 'kn',
  CUP: '₱',
  CZK: 'Kč',
  DKK: 'kr',
  DOP: 'RD$',
  XCD: '$',
  EGP: '£',
  SVC: '$',
  EUR: '€',
  FKP: '£',
  FJD: '$',
  GHS: '¢',
  GIP: '£',
  GTQ: 'Q',
  GGP: '£',
  GYD: '$',
  HNL: 'L',
  HKD: '$',
  HUF: 'Ft',
  ISK: 'kr',
  INR: '₹',
  IDR: 'Rp',
  IRR: '﷼',
  IMP: '£',
  ILS: '₪',
  JMD: 'J$',
  JPY: '¥',
  JEP: '£',
  KZT: 'лв',
  KPW: '₩',
  KRW: '₩',
  KGS: 'лв',
  LAK: '₭',
  LBP: '£',
  LRD: '$',
  MKD: 'ден',
  MYR: 'RM',
  MUR: '₨',
  MXN: '$',
  MNT: '₮',
  MZN: 'MT',
  NAD: '$',
  NPR: '₨',
  ANG: 'ƒ',
  NZD: '$',
  NIO: 'C$',
  NGN: '₦',
  NOK: 'kr',
  OMR: '﷼',
  PKR: '₨',
  PAB: 'B/.',
  PYG: 'Gs',
  PEN: 'S/.',
  PHP: '₱',
  PLN: 'zł',
  QAR: '﷼',
  RON: 'lei',
  RUB: '₽',
  SHP: '£',
  SAR: '﷼',
  RSD: 'Дин.',
  SCR: '₨',
  SGD: '$',
  SBD: '$',
  SOS: 'S',
  ZAR: 'R',
  LKR: '₨',
  SEK: 'kr',
  CHF: 'CHF',
  SRD: '$',
  SYP: '£',
  TWD: 'NT$',
  THB: '฿',
  TTD: 'TT$',
  TRY: '₺',
  TVD: '$',
  UAH: '₴',
  AED: 'د.إ',
  GBP: '£',
  USD: '$',
  UYU: '$U',
  UZS: 'лв',
  VEF: 'Bs',
  VND: '₫',
  YER: '﷼',
  ZWD: 'Z$'
}

export const currencyCodeByLocale = {
  'en-GB': 'GBP',
  'en-IE': 'EUR',
  'nl-NL': 'EUR',
  'en-US': 'USD',
  'en-CA': 'CAD',
  'en-PR': 'USD',
  'en-SG': 'SGD',
  'en-HK': 'HKD',
  'zh-HK': 'HKD',
  'zh-TW': 'TWD',
  'en-CN': 'CNY',
  'zh-CN': 'CNY',
  'fr-CA': 'CAD',
  'fr-FR': 'EUR',
  'de-DE': 'EUR',
  'es-ES': 'EUR',
  'it-IT': 'EUR',
  'fr-BE': 'EUR',
  'nl-BE': 'EUR',
  'fr-LU': 'EUR',
  'pt-PT': 'EUR',
  'lt-LT': 'EUR',
  'et-EE': 'EUR',
  'lv-LV': 'EUR',
  'el-GR': 'EUR',
  'en-MT': 'EUR',
  'el-CY': 'EUR',
  'sl-SI': 'EUR',
  'de-AT': 'EUR',
  'fi-FI': 'EUR',
  'sk-SK': 'EUR',
  'sv-SE': 'SEK',
  'no-NO': 'NOK',
  'da-DK': 'DKK',
  'de-CH': 'CHF',
  'fr-CH': 'CHF',
  'it-CH': 'CHF',
  'cs-CZ': 'CZK',
  'pl-PL': 'PLN',
  'hu-HU': 'HUF',
  'ro-RO': 'RON',
  'hr-HR': 'HRK',
  'bg-BG': 'BGN',
  'pt-BR': 'BRL'
}

export const formatCurrency = (amount, locale = navigator.language) => {
  if (!amount && amount !== 0) return ''

  const currencyCode = currencyCodeByLocale[locale]

  if (locale && currencyCode) {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode
    }).format(amount)
  }

  return amount
}

export const convertCurrencyCodeToSymbol = (code: string) => {
  return currencysCodeAndSymbol[code.toUpperCase().trim()] || '$'
}
