import React, { useLayoutEffect, useState, useMemo, useCallback } from 'react'
import {
  formatDateMonthShort,
  usePrevious,
  TimePeriod,
  PeriodOptionsType
} from '@monetization/hpaip-notification-rules-react'
import { ShipmentTrackingButton } from './ShipmentTrackingButton'
import {
  getShipmentDataFromOrders,
  ShipmentData
} from './services/getShipmentDataFromOrders'
import { filterShipmentHistoryData } from './services/filterShipmentHistoryData'
import {
  sortShipmentHistoryData,
  ShipmentHistorySortType
} from './services/sortShipmentHistoryData'
import { getShipmentDescription } from './services/getShipmentDescription'
import { HistoricTable } from '../HistoricTable'
import CommonProps from '../../types/commonProps'
import { elementIds } from 'src/constants'
import { useRenderControlContext } from 'src/contexts'

interface ShipmentHistoryProps {
  shipmentHistoryTableRef: React.RefObject<HTMLDivElement>
  subscription: any
  isCissPrinter: boolean
  commonProps: CommonProps
  ordersState?: any
}

export const ShipmentHistory = ({
  shipmentHistoryTableRef,
  subscription,
  isCissPrinter,
  commonProps,
  ordersState
}: ShipmentHistoryProps) => {
  const { t, localization } = commonProps

  const { data: orders, loading: ordersLoading } = ordersState

  const [isExpanded, setIsExpanded] = useState(false)
  const [isFilteredBy, setIsFilteredBy] = useState<TimePeriod>('all')
  const [periodOptions, setPeriodOptions] = useState<PeriodOptionsType[]>([])
  const [isSortedBy, setIsSortedBy] = useState<ShipmentHistorySortType>({
    key: 'date',
    type: 'ascending'
  })
  const previousSubscription = usePrevious(subscription?.printer)

  const { renderCount } = useRenderControlContext()

  const shipmentData = useMemo(
    () => getShipmentDataFromOrders(orders),
    [orders]
  )

  const filteredShipmentHistoryData = useMemo(() => {
    if (!shipmentData) return null
    return filterShipmentHistoryData(shipmentData, isFilteredBy)
  }, [shipmentData, isFilteredBy])

  const translateDescription = useCallback(
    function (shipment: ShipmentData) {
      const printerType = isCissPrinter
        ? t('shipment.printer-type.ink-bottle', 'ink bottle')
        : t('shipment.printer-type.cartridge', 'ink cartridge')
      const { key, defaultValue } = getShipmentDescription(
        shipment,
        printerType
      )
      return t(key, {
        defaultValue,
        count: shipment.quantity,
        context: shipment.colorCode,
        printerType
      })
    },
    [t]
  )

  const shipmentDataTable = useMemo(() => {
    const newPeriod = []
    const isSameSubscription =
      previousSubscription?.subscriptionId ===
      subscription?.printer?.subscriptionId

    const shipmentData = filteredShipmentHistoryData.map((shipment) => {
      const dateYear = shipment.date.slice(0, 4)
      const hasPeriod = isSameSubscription
        ? periodOptions.find((period) => period.label === dateYear)
        : false
      if (!newPeriod.includes(dateYear) && !hasPeriod) {
        newPeriod.push(dateYear)
      }

      return {
        date: formatDateMonthShort(shipment.date, localization.locale, true),
        dateValue: shipment.date,
        description: translateDescription(shipment),
        trackingValue: shipment.tracking,
        trackingNumber: (
          <ShipmentTrackingButton
            trackingNumber={shipment.tracking}
            url={shipment.url}
            commonProps={commonProps}
          />
        )
      }
    })

    if (newPeriod.length > 0) {
      const newOptions = newPeriod
        .sort((a, b) => b - a)
        .map((period) => {
          return {
            value: `year-${period}`,
            label: period
          }
        })
      setPeriodOptions(
        isSameSubscription ? [...periodOptions, ...newOptions] : newOptions
      )
    }
    return shipmentData
  }, [filteredShipmentHistoryData, translateDescription, localization])

  const dataTable = useMemo(() => {
    if (!shipmentDataTable) return []
    return sortShipmentHistoryData(
      shipmentDataTable,
      isSortedBy,
      localization.locale
    )
  }, [shipmentDataTable, isSortedBy, localization])

  const goToHistoricTable =
    window.location.hash === elementIds.shipmentHistoryTable

  const columns = [
    {
      filter: <>{'date'}</>,
      id: 'date',
      label: t('shipment.history-table.date-label', 'Date')
    },
    {
      filter: <>{'description'}</>,
      id: 'description',
      label: t('shipment.history-table.description-label', 'Description')
    },
    {
      filter: <>{'trackingNumber'}</>,
      id: 'trackingNumber',
      label: t('shipment.history-table.tracking-label', 'Tracking number')
    }
  ]

  const handleFilter = (value) => {
    setIsFilteredBy(value)
    setIsExpanded(true)
  }

  const handleSort = (_, { id, type }) => {
    setIsSortedBy({ key: id, type })
  }

  useLayoutEffect(() => {
    if (goToHistoricTable && shipmentHistoryTableRef.current) {
      shipmentHistoryTableRef.current.scrollIntoView()
    }
  }, [goToHistoricTable, shipmentHistoryTableRef])

  if (!subscription?.isLoading && !subscription?.printer) return null

  return (
    <HistoricTable
      key={`historicTable-${isFilteredBy}-${isSortedBy}-${renderCount}`}
      className={`shipment-history-table ${
        !dataTable?.length && 'empty-table'
      }`}
      ref={shipmentHistoryTableRef}
      id={elementIds.shipmentHistoryTable}
      loading={ordersLoading}
      expanded={goToHistoricTable || isExpanded}
      onChangeExpand={(action) => console.log(action)}
      areaName="shipment-history"
      title={t('shipment.history-table.title', 'Supply shipment history')}
      dataTable={dataTable}
      columns={columns}
      preferences={{
        width: [
          { columnId: 'date', width: 'auto' },
          { columnId: 'description', width: 'auto' },
          { columnId: 'trackingNumber', width: 'auto' }
        ],
        defaultOrder: ['date', 'description', 'trackingNumber'],
        sortBy: {
          id: isSortedBy.key,
          type: isSortedBy.type
        }
      }}
      onSort={handleSort}
      onFilter={handleFilter}
      isFilteredBy={isFilteredBy}
      periodOptions={periodOptions}
      commonProps={commonProps}
    />
  )
}
