import React from 'react'
import { FaqCard } from '../../FaqCard'
import { SupportText } from '../../SupportText'
import { Container, FaqPanel } from '../styles'
import ErrorSection from '../../ErrorSection'
import CommonProps from '../../../types/commonProps'

interface ErrorContentProps {
  isCissPrinter: boolean
  commonProps: CommonProps
}

const ErrorContent: React.FunctionComponent<ErrorContentProps> = (
  props: ErrorContentProps
) => {
  const { isCissPrinter, commonProps } = props
  const { t } = commonProps

  return (
    <Container>
      <ErrorSection
        title={t('error-section.title', 'Something went wrong')}
        description={t(
          'error-section.description',
          'Your shipment information did not load.'
        )}
        btnText={t('error-section.button', 'Please refresh page')}
      />
      <FaqPanel isCissPrinter={isCissPrinter}>
        <FaqCard commonProps={commonProps} isCissPrinter={isCissPrinter} />
        {!isCissPrinter && (
          <SupportText shipmentCards={[]} commonProps={commonProps} />
        )}
      </FaqPanel>
    </Container>
  )
}

export default ErrorContent
