import styled from 'styled-components'
import { EventWrapper, Link } from '@monetization/hpaip-ui-shared-components'
import { tokens } from '@veneer/tokens'
const { size2 } = tokens.typography
export const ShipmentTrackingButtonContainer = styled.div`
  display: inline-block;
`

export const StyledEventWrapperButton = styled(EventWrapper)`
  && {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important;
  }
`
export const StyledAnchor = styled(Link)`
  font-size: ${size2};
`
