import { useState, useEffect, useCallback } from 'react'
import { useLazyGetProducts } from '../api/services'
import { findCoreOfferPlan } from '../api/services/products'

export interface PrinterList {
  id: number
  label: string
  planId: string
}

interface UseFetchMultipleSkusResult {
  printerList: PrinterList[]
  loading: boolean
  error: boolean
}

export const getProductParentSku = (subscriptionData) => {
  const productSkus: string[] = []
  const parentSkus: string[] = []
  const multipleSubId: string[] = []

  subscriptionData?.forEach((subscription: any) => {
    subscription?.entities?.forEach((entity: any) => {
      if (entity?.entityType === 'iot-printer') {
        productSkus.push(entity.product.value.productSku)
        parentSkus.push(entity.product.value.parentProductSku)
        multipleSubId.push(subscription.subscriptionId)
      }
    })
  })

  return { productSkus, parentSkus, multipleSubId }
}

const fetchPrinterList = async (
  parentSkus: string[],
  productSkus: string[],
  multipleSubId: string[],
  getProducts,
  setPrinterList,
  setError
) => {
  for (let index = 0; index < parentSkus.length; index++) {
    const item = parentSkus[index]

    try {
      await new Promise((resolve, reject) => {
        getProducts({
          variables: { sku: item },
          onCompleted: (data) => {
            const coreOfferPlan = findCoreOfferPlan(data, productSkus[index])
            const product: PrinterList = {
              planId: multipleSubId[index],
              id: index,
              label: coreOfferPlan?.title
            }
            setPrinterList((prevData) => [...prevData, product])
            resolve(true)
          },
          onError: (error) => {
            reject(error)
            setError(true)
          }
        })
      })
    } catch (error) {
      console.error(`Error processing SKU ${item} at index ${index}:`, error)
    }
  }
}

const useMultipleSubscriptions = (
  multipleSubscriptions: any[]
): UseFetchMultipleSkusResult => {
  const [printerList, setPrinterList] = useState<PrinterList[]>([])
  const [getProducts] = useLazyGetProducts()
  const [error, setError] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const processSkus = useCallback(
    async (
      parentSkus: string[],
      productSkus: string[],
      multipleSubId: string[]
    ) => {
      setLoading(true)
      await fetchPrinterList(
        parentSkus,
        productSkus,
        multipleSubId,
        getProducts,
        setPrinterList,
        setError
      )
      setLoading(false)
    },
    [getProducts]
  )

  useEffect(() => {
    const fetchMultipleSku = async () => {
      const { productSkus, parentSkus, multipleSubId } = getProductParentSku(
        multipleSubscriptions
      )
      await processSkus(parentSkus, productSkus, multipleSubId)
    }

    if (multipleSubscriptions?.length > 1) {
      fetchMultipleSku()
    }
  }, [multipleSubscriptions, processSkus])

  return { printerList, loading, error }
}

export default useMultipleSubscriptions
