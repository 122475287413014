import {
  OrderStatus,
  ProductType
} from '@monetization/hpaip-notification-rules-react'
import { TranslationObject } from 'src/types/translation'

type StatusTextWithTranslationKey = {
  [k in Exclude<
    OrderStatus,
    'received' | 'onhold' | 'releaseHold' | 'error' | 'unknown'
  >]: TranslationObject
}

const cardSubtitlesByStatus: Record<
  Exclude<ProductType, 'hardware'>,
  StatusTextWithTranslationKey
> = {
  instantpaper: {
    new: {
      key: 'shipment.print-cards.subtitles.paper.new',
      defaultValue: "We'll send more paper soon."
    },
    processing: {
      key: 'shipment.print-cards.subtitles.paper.processing',
      defaultValue: 'Your paper shipment is processing.'
    },
    cancelled: {
      key: 'shipment.print-cards.subtitles.paper.canceled',
      defaultValue: 'Your paper shipment was canceled.'
    },
    shipped: {
      key: 'shipment.print-cards.subtitles.paper.shipped',
      defaultValue: 'Your paper is on its way!'
    },
    undelivered: {
      key: 'shipment.print-cards.subtitles.paper.undelivered',
      defaultValue:
        "There's been an issue while attempting to deliver your paper."
    },
    delivered: {
      key: 'shipment.print-cards.subtitles.paper.delivered',
      defaultValue:
        'You have paper available to load. Check around your workspace; it might have arrived some time ago.'
    }
  },
  instantink: {
    new: {
      key: 'shipment.print-cards.subtitles.cartridge.new',
      defaultValue: `We'll send another {{printerType}} soon.`
    },
    processing: {
      key: 'shipment.print-cards.subtitles.cartridge.processing',
      defaultValue: 'Your {{printerType}} shipment order is processing.'
    },
    cancelled: {
      key: 'shipment.print-cards.subtitles.cartridge.cancelled',
      defaultValue: 'Your {{printerType}} shipment has been canceled.'
    },
    shipped: {
      key: 'shipment.print-cards.subtitles.cartridge.shipped',
      defaultValue: 'Your ink cartridge is on its way!'
    },
    undelivered: {
      key: 'shipment.print-cards.subtitles.cartridge.undelivered',
      defaultValue: `There's been an issue while attempting to deliver your {{printerType}}.`
    },
    delivered: {
      key: 'shipment.print-cards.subtitles.cartridge.delivered',
      defaultValue:
        'You have ink available to install. Check around your workspace; it might have arrived some time ago.'
    }
  }
}

const errorMessage: TranslationObject = {
  key: 'shipment.print-cards.subtitles.error',
  defaultValue:
    'An error has occurred. Contact 24/7 Pro live support for further assistance.'
}

export function getCardSubtitleByStatus(
  productType: ProductType,
  status: OrderStatus
): TranslationObject {
  if (status === 'received') {
    return cardSubtitlesByStatus[productType]?.new || errorMessage
  }

  if (status === 'onhold' || status === 'releaseHold') {
    return cardSubtitlesByStatus[productType]?.processing || errorMessage
  }

  return cardSubtitlesByStatus[productType]?.[status] || errorMessage
}
