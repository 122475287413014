import { TranslationObject } from 'src/types/translation'
import { ShipmentData } from './getShipmentDataFromOrders'

type OrderStatus =
  | 'new'
  | 'received'
  | 'processing'
  | 'shipped'
  | 'onhold'
  | 'releaseHold'
  | 'cancelled'
  | 'error'
  | 'unknown'
  | 'undelivered'
  | 'delivered'

type CartridgeColor = 'C' | 'M' | 'Y' | 'K' | 'CMY'
type OrderType = 'welcomeKit' | 'replenishment'
type States = Extract<OrderStatus, 'cancelled' | 'shipped' | 'undelivered'>
type StatusTranslations = Record<States, TranslationObject>
type OrderTypeTranslations = Record<OrderType, StatusTranslations>

const paperDescriptions: StatusTranslations = {
  cancelled: {
    key: 'shipment.history-table.descriptions.paper.cancelled',
    defaultValue: 'HP Paper Add-On Service Shipment Canceled'
  },
  shipped: {
    key: 'shipment.history-table.descriptions.paper.shipped',
    defaultValue: 'HP Paper Add-On Service Shipped'
  },
  undelivered: {
    key: 'shipment.history-table.descriptions.paper.undelivered',
    defaultValue: 'HP Paper Add-On Service Shipment Not Delivered'
  }
}

const printerDescriptions: OrderTypeTranslations = {
  replenishment: {
    cancelled: {
      key: 'shipment.history-table.descriptions.printer.replenishment-kit.cancelled',
      defaultValue: 'Printer Shipment Canceled'
    },
    shipped: {
      key: 'shipment.history-table.descriptions.printer.replenishment-kit.shipped',
      defaultValue: 'Printer Shipped'
    },
    undelivered: {
      key: 'shipment.history-table.descriptions.printer.replenishment-kit.undelivered',
      defaultValue: 'Printer Shipment Not Delivered'
    }
  },
  welcomeKit: {
    cancelled: {
      key: 'shipment.history-table.descriptions.printer.welcome-kit.cancelled',
      defaultValue: 'Printer Welcome Kit Shipment Canceled'
    },
    shipped: {
      key: 'shipment.history-table.descriptions.printer.welcome-kit.shipped',
      defaultValue: 'Printer Welcome Kit Shipment Shipped'
    },
    undelivered: {
      key: 'shipment.history-table.descriptions.printer.welcome-kit.undelivered',
      defaultValue: 'Printer Welcome Kit Shipment Not Delivered'
    }
  }
}

const colors: Record<CartridgeColor, string> = {
  C: 'Cyan',
  M: 'Magenta',
  Y: 'Yellow',
  K: 'Black',
  CMY: 'Tri-color'
}

interface CartridgeDescriptions
  extends Omit<OrderTypeTranslations, 'replenishment'> {
  replenishment(
    color: CartridgeColor,
    quantity: number,
    isPlural: boolean,
    printerType: string
  ): StatusTranslations
}

const cartridgeDescriptions: CartridgeDescriptions = {
  replenishment: (
    color: CartridgeColor,
    quantity: number,
    isPlural: boolean,
    printerType: string
  ) => ({
    cancelled: {
      key:
        'shipment.history-table.descriptions.cartridge.replenishment-kit.cancelled' +
        (isPlural ? '-plural' : ''),
      defaultValue: `${quantity} ${colors[color]} ${printerType}${
        isPlural ? 's' : ''
      } Shipment Canceled`
    },
    shipped: {
      key:
        'shipment.history-table.descriptions.cartridge.replenishment-kit.shipped' +
        (isPlural ? '-plural' : ''),
      defaultValue: `${quantity} ${colors[color]} ${printerType}${
        isPlural ? 's' : ''
      } shipped`
    },
    undelivered: {
      key:
        'shipment.history-table.descriptions.cartridge.replenishment-kit.undelivered' +
        (isPlural ? '-plural' : ''),
      defaultValue: `${quantity} ${colors[color]} ${printerType}${
        isPlural ? 's' : ''
      } Shipment Not Delivered`
    }
  }),
  welcomeKit: {
    cancelled: {
      key: 'shipment.history-table.descriptions.cartridge.welcome-kit.cancelled',
      defaultValue: 'Ink Welcome Kit Shipment Canceled'
    },
    shipped: {
      key: 'shipment.history-table.descriptions.cartridge.welcome-kit.shipped',
      defaultValue: 'Ink Welcome Kit Shipment Shipped'
    },
    undelivered: {
      key: 'shipment.history-table.descriptions.cartridge.welcome-kit.undelivered',
      defaultValue: 'Ink Welcome Kit Shipment Not Delivered'
    }
  }
}

export function getShipmentDescription(
  { productType, orderType, status, quantity, colorCode }: ShipmentData,
  printerType: string
): TranslationObject {
  const emptyDescription = {
    key: '',
    defaultValue: ''
  }

  if (productType === 'instantpaper')
    return paperDescriptions[status] || emptyDescription

  if (productType === 'hardware')
    return printerDescriptions[orderType]?.[status] || emptyDescription

  if (productType === 'instantink') {
    if (orderType === 'replenishment') {
      return (
        cartridgeDescriptions.replenishment(
          colorCode,
          quantity,
          quantity > 1,
          printerType
        )[status] || emptyDescription
      )
    }
    return cartridgeDescriptions[orderType]?.[status] || emptyDescription
  }

  return emptyDescription
}
