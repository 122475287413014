export type ShipmentHistorySortType = {
  key: 'description' | 'trackingNumber' | 'date'
  type: 'ascending' | 'descending'
}

export type TableRow = {
  date: string
  dateValue: string
  description: string
  trackingValue: string
  trackingNumber: JSX.Element
}

const getSortDirection = (valueA, valueB, key) => {
  return valueA[key] < valueB[key] ? -1 : valueA[key] > valueB[key] ? 1 : 0
}

export function sortShipmentHistoryData(
  tableRows: TableRow[],
  { key, type }: ShipmentHistorySortType,
  locale: string
) {
  if (!tableRows) return

  return tableRows.sort((a, b) => {
    const multiplier = type === 'ascending' ? 1 : -1

    if (key === 'description') {
      return multiplier * a[key].localeCompare(b[key], locale)
    }
    if (key === 'trackingNumber') {
      return multiplier * getSortDirection(a, b, 'trackingValue')
    }
    if (key === 'date') {
      return multiplier * getSortDirection(a, b, 'dateValue')
    }
  })
}
