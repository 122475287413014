import React from 'react'
import {
  Title,
  CardCustomColor,
  Content,
  StyledDate,
  AccordionHeader
} from './styles'
import { PrintCardsAccordion } from './PrintCardsAccordion'
import { PrintCardAccordionHeader } from './PrintCardAccordionHeader'
import { PrintCardAccordionContent } from './PrintCardAccordionContent'
import { ShipmentCard } from '../../types/shipmentCard'
import CommonProps from '../../types/commonProps'

type PrintCardContentProps = {
  shipmentCard: ShipmentCard
  shipmentHistoryTableRef: React.RefObject<HTMLDivElement>
  isCustomError?: boolean
  isCissPrinter?: boolean
  commonProps: CommonProps
}

export const PrintCardContent = ({
  shipmentCard,
  shipmentHistoryTableRef,
  isCustomError,
  isCissPrinter,
  commonProps
}: PrintCardContentProps) => {
  const { localization } = commonProps
  const [lastShipmentInfo] = shipmentCard.history
  const formatDate = (d: Date) =>
    d.toLocaleDateString(localization.locale, {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    })
  const eta = formatDate(new Date(shipmentCard?.eta))

  return (
    <>
      <CardCustomColor shipmentInfoType={shipmentCard.type} />
      <Content>
        <Title>{shipmentCard.title}</Title>
        <StyledDate>{eta}</StyledDate>
        <PrintCardsAccordion
          key={lastShipmentInfo.id}
          title={shipmentCard.title}
          veneerAccordion={{
            items: [
              {
                header: {
                  startArea: (
                    <AccordionHeader>
                      <PrintCardAccordionHeader
                        shipmentCard={shipmentCard}
                        isCustomError={isCustomError}
                        eta={eta}
                        isCissPrinter={isCissPrinter}
                        commonProps={commonProps}
                      />
                    </AccordionHeader>
                  )
                },
                expanded: shipmentCard.expanded,
                disabled: shipmentCard.expanded,
                content: (
                  <PrintCardAccordionContent
                    shipmentCard={shipmentCard}
                    shipmentHistoryTableRef={shipmentHistoryTableRef}
                    commonProps={commonProps}
                  />
                ),
                id: `accordion-${shipmentCard.title}`
              }
            ]
          }}
        />
      </Content>
    </>
  )
}
