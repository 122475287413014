import { LDProvider } from '@monetization/hpaip-notification-rules-react'
import { getStack, getEnvironmentVariables } from '../src/helpers'
import React, { useEffect, useState } from 'react'
import resources from '../src/assets/locale'
import OnboardingService from '../src/components/OnboardingService'
import ShipmentTracking from '../src/components/ShipmentTracking'
import projectNames from '../src/configs/projectNames'
import { TranslatorFunctionType } from '../src/types/localization'
import { MfePropsType } from '../src/types/mfeProps'
import { ShellProps } from '../src/types/shell'

declare global {
  interface Window {
    Shell: ShellProps
  }
}

type ShipmentTrackingProps = {
  country?: string
  language?: string
}

/**
 * @function Root Main function
 * @param props
 * @returns
 */
export default function Root({
  // used only to test onboarding services
  useOnboardinServiceExample,
  ...props
}: MfePropsType & ShipmentTrackingProps) {
  // You can find all Shell´s props here. See "../src/types/shell" for more details.
  const [t, setT] = useState<TranslatorFunctionType>()
  const interfaces = window.Shell.v1
  const Component = useOnboardinServiceExample
    ? OnboardingService
    : ShipmentTracking

  // Create a translator function by providing the resource files
  useEffect(() => {
    interfaces.localization
      .createTranslatorFunction(resources)
      .then((v) => setT(() => v))
  }, [interfaces])

  // set environment variables
  const envVariables = getEnvironmentVariables(getStack(props?.stack))
  const clientSideID = envVariables.variables.LAUNCHDARKLY_CLIENTSIDE_ID

  // Wait for the translator function creation to show the content
  if (!t) {
    return null
  }

  // You can find the "stack" information by props
  // const stack = props.stack.
  return (
    <section
      className={`${projectNames.namespace}`}
      id={projectNames.packageJsonName}
    >
      <LDProvider
        clientSideID={clientSideID || '61a0ce4cb067d50e9a0ee06c'}
        user={{ key: 'test@email.com' }}
      >
        <Component {...{ ...props, ...interfaces, t }} />
      </LDProvider>
    </section>
  )
}
