import React, { useMemo } from 'react'
import { CardDetailsTitle } from './styles'
import { ShipmentCard } from '../../types/shipmentCard'
import { PrintCardAccordionContentHistoryItem } from './PrintCardAccordionContentHistoryItem'
import CommonProps from '../../types/commonProps'
import { Link } from '@monetization/hpaip-ui-shared-components'
import { elementIds } from 'src/constants'
import { useRenderControlContext } from 'src/contexts'

type PrintCardAccordionContentProps = {
  shipmentCard: ShipmentCard
  shipmentHistoryTableRef: React.RefObject<HTMLDivElement>
  commonProps: CommonProps
}

export const PrintCardAccordionContent = ({
  shipmentCard,
  shipmentHistoryTableRef,
  commonProps
}: PrintCardAccordionContentProps) => {
  const { t } = commonProps
  const { triggerRender } = useRenderControlContext()

  const scrollToShipmentHistory = () => {
    if (shipmentHistoryTableRef.current) {
      setTimeout(() => {
        shipmentHistoryTableRef.current.scrollIntoView()
      }, 100)
    }
  }

  const lastThreeOrders = useMemo(() => {
    if (shipmentCard.history?.length > 1)
      return shipmentCard.history.slice(1, 4)
    return []
  }, [shipmentCard])

  return (
    <>
      {lastThreeOrders.length > 0 && (
        <>
          <CardDetailsTitle>
            {t('shipment.print-cards.previous-shipments', 'Previous Shipments')}
          </CardDetailsTitle>
          {lastThreeOrders.map((history, index, array) => (
            <PrintCardAccordionContentHistoryItem
              key={history.id + index}
              historyInfo={history}
              shipmentCardTitle={shipmentCard.title}
              showDivider={index != array.length - 1}
              commonProps={commonProps}
            />
          ))}
        </>
      )}
      <Link
        href={elementIds.shipmentHistoryTable}
        onClick={() => {
          window.location.hash = elementIds.shipmentHistoryTable
          scrollToShipmentHistory()
          triggerRender()
        }}
        data-testid="view-shipment-history"
      >
        {t(
          'shipment.print-cards.shipment-history',
          'View your Entire Shipment History'
        )}
      </Link>
    </>
  )
}
