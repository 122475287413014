import React, { Dispatch, SetStateAction } from 'react'
import {
  ContentContainer,
  PeriodTimeSelect,
  ContentWrapper,
  DownloadAllInvoicesButton,
  StyledTable
} from './styles'
import IconDownload from '@veneer/core/dist/esm/scripts/icons/icon_download'
import {
  HistoricTableColumnType,
  TimePeriod,
  Preferences,
  PageSizeOptionsType,
  PeriodOptionsType
} from '../../types/historicTableType'
import CommonProps from '../../types/commonProps'

export interface HistoricTableContentProps {
  className: string
  columns: HistoricTableColumnType[]
  preferences: Preferences
  onSort: (_: any, { id, type }: { id: string; type: string }) => void
  downloadAll?: boolean
  setPageSize: Dispatch<SetStateAction<number>>
  paginationSize: number
  timePeriod: TimePeriod
  PageSizeOptions: PageSizeOptionsType[]
  periodOptions: PeriodOptionsType[]
  paginationHandler: (newPage: number) => void
  onChangePeriod: (value: TimePeriod) => void
  paginatedData: any[]
  totalLength: number
  pageSize: number
  currentPage: number
  hasNextPage?: boolean
  loading?: boolean
  commonProps?: CommonProps
}

export const HistoricTableContent: React.FunctionComponent<
  HistoricTableContentProps
> = ({
  className,
  columns,
  preferences,
  onSort,
  downloadAll,
  setPageSize,
  paginationSize,
  timePeriod,
  PageSizeOptions,
  periodOptions,
  paginationHandler,
  onChangePeriod,
  paginatedData,
  totalLength,
  pageSize,
  currentPage,
  hasNextPage,
  loading,
  commonProps
}) => {
  const { t } = commonProps
  return (
    <ContentContainer>
      <ContentWrapper>
        <PeriodTimeSelect
          clearIcon={false}
          options={periodOptions}
          id="select-period"
          placement="bottom"
          onChange={(e) => onChangePeriod(e.value as TimePeriod)}
          value={[timePeriod]}
        />
        <DownloadAllInvoicesButton
          download={downloadAll}
          leadingIcon={<IconDownload size={17} />}
        >
          {/**
           * Language key for the default error message
           * @param {historic-table.download} 'Download All Invoices' */}
          {t('historic-table.download', 'Download All Invoices')}
        </DownloadAllInvoicesButton>
      </ContentWrapper>
      <StyledTable
        className={className}
        columns={columns}
        data={paginatedData}
        loading={loading}
        loadingDataLength={Number(pageSize)}
        onSort={onSort}
        preferences={preferences}
        pagination={{
          currentPage,
          onPageChange: paginationHandler,
          onPageSizeChange: (_, option) => {
            setPageSize(Number(option.value))
          },
          pageSizeOptions: PageSizeOptions,
          pageSize: Number(pageSize),
          totalItems: totalLength,
          paginationNavigationSize: paginationSize
        }}
        hasNextPage={hasNextPage}
        disableSelect={paginatedData.length < 1}
      />
    </ContentContainer>
  )
}
