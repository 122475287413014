import React from 'react'
import { CdmEvent, useAnalytics } from '../../contexts/AnalyticsContext'

export interface EventWrapperProps {
  eventProps: CdmEvent
}

export const EventWrapper: React.FC<EventWrapperProps> = ({
  eventProps,
  children
}) => {
  const { publishEvent } = useAnalytics()

  const customChildren = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, {
        ...child.props,
        onClick: async (...args) => {
          try {
            await publishEvent(eventProps)
          } finally {
            child.props.onClick?.(...args)
          }
        }
      })
    }

    return child
  })

  return <>{customChildren}</>
}
