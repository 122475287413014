import styled from 'styled-components'
import tokens from '@veneer/tokens'

const { color, layout, typography } = tokens

export const Container = styled.div<{ hasShipmentCards: boolean }>`
  && {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    height: fit-content;
    background: transparent;
    p {
      font-size: ${typography.size2};
      line-height: ${typography.lineHeight3};
      color: ${color.gray12};
    }

    a {
      font-size: ${typography.size2};
      line-height: ${typography.lineHeight2};
      color: ${color.hpBlue7};
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        margin-right: ${layout.size2};
        color: ${color.hpBlue7};
      }
      &:first-of-type {
        padding: ${layout.size5} 0;
        float: right;
      }
    }

    @media only screen and (max-width: ${layout.smMax}) {
      padding: ${layout.size8} 0;
      width: 100%;
      align-self: start;
      display: block;
      float: right;
      text-align: right;
    }
  }
`
