import styled from 'styled-components'
import { tokens } from '@veneer/tokens'

const { white, gray9, gray12 } = tokens.color
const {
  size0,
  lineHeight1,
  lineHeight2,
  size2: fontSize2,
  size1: fontSize1
} = tokens.typography
const { cornerRadius3, size2, size1, size3 } = tokens.layout
interface SelectedPrinterProps {
  isOpen: boolean
}
export const CardPrinter = styled.div`
  box-sizing: content-box;
  padding: 0px;
  width: auto;
  position: relative;
  @media (min-width: 640px) {
    width: 304px;
  }
`
export const PrinterCard = styled.div`
  width: auto;
  height: 200px;
  border-radius: 0px 0px ${cornerRadius3} ${cornerRadius3};
  border: 1px solid #dbdbdb;
  position: absolute;
  overflow: auto;
  z-index: 1;
  background: ${white};
  @media (min-width: 640px) {
    width: 302px;
  }
`
export const PrinterList = styled.div`
  margin-bottom: ${size2};
`
export const PrinterItemList = styled.div`
  display: flex;
  flex-direction: row;
  padding: ${size2} ${size3};
  align-items: center;
  gap: ${size2};
  :hover {
    background: var(--light-primary-background-light, rgba(2, 120, 171, 0.05));
  }
`
export const SelectedPrinter = styled.div<SelectedPrinterProps>`
  width: auto;
  height: auto;
  border-radius: ${(props) => (props.isOpen ? ' 12px 12px 0 0' : '12px')};
  border: 1px solid #dbdbdb;
  padding: ${size2} ${size3};
  background: ${white};
`
export const CardItem = styled.div`
  display: flex;
  justify-content: center;
  gap: ${size2};
`
export const Image = styled.img`
  width: 40px;
  height: 36px;
`
export const CardText = styled.p`
  flex-grow: 1;
  margin: 1px 0 0 ${size1};
`
export const CardTextHeader = styled.p`
  flex-grow: 1;
  margin: 1px 0 0 ${size1};
`
export const CardHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`
export const CardTitle = styled.p`
  margin: 0px;
`
export const CardIcon = styled.div`
  display: flex;
  align-items: center;
`
export const CardSubTitle = styled.p`
  font-family: Forma DJR UI;
  font-style: normal;
  font-weight: 400;
  font-size: ${size0};
  line-height: ${lineHeight1};
  color: ${gray9};
  margin: 0px;
`
export const CardLabel = styled.p`
  font-family: Forma DJR UI;
  font-size: ${fontSize2};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
  color: ${gray12};
  margin: 0px;
`
export const PrinterTitle = styled.p`
  font-family: 'Forma DJR UI';
  color: ${gray12};
  margin: 0px;
  font-size: ${fontSize1};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight2};
`
export const PrinterId = styled.p`
  font-family: Forma DJR UI;
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: ${gray12};
  margin: 0px;
`
export const SelectTitle = styled.div`
  font-family: Forma DJR UI;
  height: 28px;
  padding: 6px ${size3} 6px ${size3};
  font-size: ${size0};
  font-style: normal;
  font-weight: 400;
  line-height: ${lineHeight1};
  color: ${gray12};
`
