import { useRef } from 'react'

export function usePrevious<T>(value: T): T {
  const previousRef = useRef(null)

  const previous = previousRef.current

  if (value !== previous) {
    previousRef.current = value
  }

  return previous
}
