import React from 'react'
import { ShipmentTrackingButtonContainer, StyledAnchor } from './styles'

interface ShipmentTrackingButtonProps {
  trackingNumber?: string
  url?: string
  commonProps
}

export const ShipmentTrackingButton = ({
  trackingNumber,
  url,
  commonProps
}: ShipmentTrackingButtonProps) => {
  const { t } = commonProps
  return (
    <ShipmentTrackingButtonContainer>
      {url && trackingNumber ? (
        <StyledAnchor href={url} target="_blank">
          {trackingNumber}
        </StyledAnchor>
      ) : (
        <p>
          {trackingNumber ||
            t(
              'shipment.history-table.table-content.not-available',
              'Not available yet'
            )}
        </p>
      )}
    </ShipmentTrackingButtonContainer>
  )
}
