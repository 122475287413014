import {
  OrderStatus,
  ProductType
} from '@monetization/hpaip-notification-rules-react'

export const shipmentCardTypes = [
  'cyan',
  'magenta',
  'yellow',
  'black',
  'tricolor',
  'paper',
  'printer'
] as const

export type ShipmentCardType = (typeof shipmentCardTypes)[number]

export const productTypeByCardType: Record<ShipmentCardType, ProductType> = {
  cyan: 'instantink',
  magenta: 'instantink',
  yellow: 'instantink',
  black: 'instantink',
  tricolor: 'instantink',
  paper: 'instantpaper',
  printer: 'hardware'
}

export const printCardOrderWeight: Record<ShipmentCardType, number> = {
  black: 0,
  cyan: 1,
  magenta: 2,
  yellow: 3,
  tricolor: 4,
  paper: 5,
  printer: 6
}

export const colorsMap = {
  C: 'cyan',
  M: 'magenta',
  Y: 'yellow',
  K: 'black',
  CMY: 'tricolor'
} as const

export const productsMap = {
  hardware: 'printer',
  instantpaper: 'paper'
} as const

export interface HistoryInfo {
  id: string
  date: Date
  url: string
  quantity?: number
}

export interface ShipmentCard {
  title: string
  type: ShipmentCardType
  productType: ProductType
  status: OrderStatus
  position: number
  offline: boolean
  expanded: boolean
  history: Array<HistoryInfo>
  eta?: string
}

export type ShipmentCardsByType = Partial<
  Record<ShipmentCardType, ShipmentCard>
>
