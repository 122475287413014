import React from 'react'
import { navigationLinks } from '@monetization/hpaip-notification-rules-react'
import { ShipmentCard } from '../../types/shipmentCard'
import { getCardSubtitleByStatus } from './getCardSubtitleByStatus'
import PrinterOfflineModule from './PrinterOfflineModule'
import CommonProps from '../../types/commonProps'
import { Link } from '@monetization/hpaip-ui-shared-components'

type PrintCardAccordionHeaderSubtitleChildrenProps = {
  shipmentCard: ShipmentCard
  isCustomError?: boolean
  eta?: string
  isCissPrinter?: boolean
  commonProps: CommonProps
}

const acceptedStatus = [
  'new',
  'received',
  'processing',
  'shipped',
  'onhold',
  'releaseHold',
  'cancelled',
  'delivered'
]

export const PrintCardAccordionSubtitle: React.FunctionComponent<
  PrintCardAccordionHeaderSubtitleChildrenProps
> = ({ shipmentCard, isCustomError, eta, isCissPrinter, commonProps }) => {
  const { orders } = navigationLinks

  const { t } = commonProps
  const isError = !acceptedStatus.includes(shipmentCard.status) || isCustomError
  const printerType = isCissPrinter
    ? t('shipment.card-printer-type.ink-bottle-type', 'ink bottle')
    : t('shipment.card-printer-type.cartridge-type', 'ink cartridge')
  if (shipmentCard.offline) {
    return (
      <PrinterOfflineModule
        cardTitle={shipmentCard.title}
        commonProps={commonProps}
      />
    )
  }

  if (!isError && shipmentCard.type === 'printer') {
    return (
      <>
        {t('shipment.print-cards.order-history.part1', 'Visit ')}
        <Link path={orders} fontSize={2}>
          {t('shipment.print-cards.order-history.link', 'Order History')}
        </Link>
        {t(
          'shipment.print-cards.order-history.part2',
          ' for status on this shipment'
        )}
      </>
    )
  }

  /* istanbul ignore else */
  if (isCustomError) {
    return (
      <>
        {t(
          'shipment.print-cards.subtitles.error',
          'An error has occurred. Contact 24/7 Pro live support for further assistance.'
        )}
      </>
    )
  }

  if (
    shipmentCard.productType === 'instantink' &&
    shipmentCard.status === 'shipped'
  )
    return (
      <>
        {isCissPrinter ? (
          <>
            {t(
              'shipment.print-cards.subtitles.cartridge.shippedDated-cissPrinter',
              {
                eta: eta,
                printerType: printerType,
                defaultValue:
                  'Your replacement ink bottle was shipped on {{eta}}.'
              }
            )}
          </>
        ) : (
          <>
            {t('shipment.print-cards.subtitles.cartridge.shippedDated', {
              eta: eta,
              printerType: printerType,
              defaultValue: 'Your replacement Ink was shipped on {{eta}}.'
            })}
          </>
        )}
      </>
    )

  const { key, defaultValue } = getCardSubtitleByStatus(
    shipmentCard.productType,
    shipmentCard.status
  )

  return <>{t(key, { printerType, defaultValue })}</>
}
