import React, { useState } from 'react'
import {
  CardPrinter,
  PrinterCard,
  PrinterList,
  SelectedPrinter,
  CardItem,
  Image,
  CardText,
  CardHeader,
  CardTitle,
  CardIcon,
  CardSubTitle,
  PrinterTitle,
  PrinterId,
  CardTextHeader,
  CardLabel,
  SelectTitle,
  PrinterItemList
} from './styles'
import {
  IconChevronDown,
  IconChevronUp
} from '@veneer/core/dist/esm/scripts/icons'
import { TranslatorFunctionType } from '../../../types'
import { DefaultExportProps } from '../../../types/defaultExportProps'
import Images from '../../../assets/images'
export interface PrinterData {
  id: number
  label: string
  planId: string
}

interface SelectPrinterProps extends DefaultExportProps {
  PrinterDetails: PrinterData[]
  t: TranslatorFunctionType
  defaultPrinter: number
  onPrinterSelect: Function
  isOpen: boolean
}

export const PrinterListOption: React.FunctionComponent<SelectPrinterProps> = ({
  t,
  PrinterDetails,
  defaultPrinter,
  onPrinterSelect
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selectedItem, setSelectedItem] = useState<PrinterData>(
    PrinterDetails.find((item) => item.id === defaultPrinter) || null
  )

  const toggleMenu = () => {
    setIsOpen(!isOpen)
  }

  const selectItem = (data: PrinterData) => {
    setSelectedItem(data)
    setIsOpen(false)
    onPrinterSelect(data)
  }

  const items = PrinterDetails || []

  return (
    <CardPrinter>
      <SelectedPrinter
        onClick={toggleMenu}
        data-testid="selected-printer"
        isOpen={isOpen}
      >
        {selectedItem ? (
          <>
            <CardItem>
              <Image src={Images.PrinterImg} alt={'ImgPrinter'} />
              <CardTextHeader>
                <CardSubTitle>
                  {t('hp-printer-device-details.title', 'Select printer')}
                </CardSubTitle>
                <CardLabel> {selectedItem.label}</CardLabel>
              </CardTextHeader>
              <CardIcon>
                {isOpen ? (
                  <IconChevronUp size={24} color="colorGray10" />
                ) : (
                  <IconChevronDown size={24} color="colorGray10" />
                )}
              </CardIcon>
            </CardItem>
          </>
        ) : (
          <CardHeader>
            <CardTitle>
              {t('hp-printer-device-details.title', 'Select printer')}
            </CardTitle>
            {isOpen ? (
              <IconChevronUp size={24} color="colorGray10" />
            ) : (
              <IconChevronDown size={24} color="colorGray10" />
            )}
          </CardHeader>
        )}
      </SelectedPrinter>
      {isOpen && (
        <PrinterCard>
          <SelectTitle>
            {t('hp-printer-device-details.selecttitle', 'HP All-In PRINTERS')}
          </SelectTitle>
          {items.map((item) => (
            <PrinterList key={item.id} data-testid="printer-list-item">
              <PrinterItemList>
                <Image src={Images.PrinterImg} alt={'ImgPrinter'} />
                <CardText onClick={() => selectItem(item)}>
                  <PrinterTitle>{item.label}</PrinterTitle>
                  <PrinterId>
                    {t('hp-printer-device-details.planId', 'Plan ID: ')}
                    {item.planId}
                  </PrinterId>
                </CardText>
              </PrinterItemList>
            </PrinterList>
          ))}
        </PrinterCard>
      )}
    </CardPrinter>
  )
}
