import {
  Order,
  LazyFetchState
} from '@monetization/hpaip-notification-rules-react'

import {
  colorsMap,
  HistoryInfo,
  printCardOrderWeight,
  productsMap,
  productTypeByCardType,
  ShipmentCard,
  ShipmentCardsByType,
  shipmentCardTypes
} from '../../../types/shipmentCard'

export const getShipmentInfoFromOrders = (
  isDeviceOffline: boolean,
  ordersState: LazyFetchState<Order[]>,
  printCardLabel: any
): ShipmentCard[] => {
  const orders = ordersState?.data
  if (!orders || orders.length < 1) return []
  const sortedOrders = orders
    .filter((order) => !!order.timeStamp)
    .sort((a, b) => {
      return new Date(b.timeStamp).getTime() - new Date(a.timeStamp).getTime()
    })

  const cards: ShipmentCardsByType = shipmentCardTypes.reduce(
    (cards, type) => ({
      ...cards,
      [type]: {
        type,
        productType: productTypeByCardType[type],
        title: printCardLabel[type],
        position: printCardOrderWeight[type],
        offline: isDeviceOffline,
        expanded: false,
        history: [],
        eta: ''
      }
    }),
    {}
  )

  const shipmentCardsByType = sortedOrders.reduce((cards, order) => {
    const historyInfo: HistoryInfo = {
      id: order.trackingNumber,
      date: new Date(order.timeStamp),
      url: order.trackingUrl
    }

    if (order.productType === 'instantpaper') {
      const cardType = productsMap[order.productType]

      if (cards[cardType].history.length === 0) {
        cards[cardType].status = order.status
        cards[cardType].eta = order?.timeStamp
      }

      cards[cardType].history.push(historyInfo)

      return cards
    }

    order.cartridges?.forEach(({ colorCode, quantity }) => {
      const cardType = colorsMap[colorCode]

      if (cards[cardType]?.history.length === 0) {
        cards[cardType].status = order.status
        cards[cardType].eta = order?.timeStamp
      }

      cards[cardType]?.history.push({ ...historyInfo, quantity })
    })

    return cards
  }, cards)

  return Object.values(shipmentCardsByType as ShipmentCardsByType)
    .filter(({ history }) => history.length)
    .sort((a, b) => a.position - b.position)
}
